
import { Vue, Options, prop } from "vue-class-component";
import { MainAppController } from "../../controllers/MainAppController";

class Props {
  modelValue = prop<boolean>({
    required: true
  });
  data = prop<Array<any>>({
    default: [],
    type: Array
  });
  maxHeight = prop<string>({
    default: "",
    type: String
  });
  isCustom = prop<boolean>({
    default: false,
    type: Boolean
  });
  single = prop<boolean>({
    default: false,
    type: Boolean
  });
  image = prop<string>({
    default: "",
    type: String
  });
}
@Options({
  name: "PreviewImageForum",
  emits: ["update:modelValue", "close"]
})
export default class extends Vue.with(Props) {
  mounted() {
    this.onKeyClose();
  }
  unmounted() {
    this.onKeyClose();
  }
  onKeyClose() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", e => {
      if (e.key === "Escape") {
        this.onClose();
      }
    });
  }
  onClose() {
    this.$emit("update:modelValue", false);
    this.$emit("close");
  }
  get maxHeightDefault() {
    return (5 / 6) * MainAppController.screenSize.height;
  }

  get newData() {
    const temp: string[] = this.data;

    if (this.isCustom) {
      temp.unshift("");
      return temp;
    }
    return temp;
  }
  formatSourceImage(image: string) {
    if (image.includes("http")) {
      return image;
    }
    return require(`@/app/ui/assets/images/${image}`);
  }

  onClickImage(url: string) {
    if (url) {
      url = url.includes("http") ? url : `https://${url}`;
      window.open(url, "_blank");
    }
  }
}
