import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-montserrat"
}
const _hoisted_2 = { class: "\n        w-fit\n        relative\n      " }
const _hoisted_3 = { class: "flex justify-center w-12 h-10" }
const _hoisted_4 = { class: "absolute opacity-50 w-full h-full inset-0 flex items-center justify-center z-60 rounded bg-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icons = _resolveComponent("Icons")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode("img", {
              src: _ctx.image,
              class: "cursor-pointer rounded w-full",
              alt: _ctx.item
            }, null, 8, ["src", "alt"])
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_Icons, {
              name: "trash-concentrated",
              class: "w-4 cursor-pointer"
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}